import { useQuery, useMutation } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';
import { BUSINESS_CREATE_MUTATION } from '@/modules/restaurants/compositions/business';

import { BUSINESSES } from './graphql';
import { computed } from 'vue';

export function useBusinesses(variables) {
  const { error } = useNotification();

  const { result, loading, onError, refetch } = useQuery(BUSINESSES, variables, () => ({
    fetchPolicy: 'cache-first',
    debounce: 500,
  }));

  const businesses = computed(() => result.value ?? {});

  onError((err) => {
    console.error('useBusinesses', err);
    error();
  });

  return {
    businesses,
    loading,
    refetch,
  };
}

export function createBusiness() {
  const { error } = useNotification();
  const { mutate, loading, onDone, onError } = useMutation(BUSINESS_CREATE_MUTATION);

  onError((err) => {
    console.error('createBusiness', err);
    error();
  });

  return { mutate, loading, onDone };
}
