<template>
  <div class="businesses-table-actions">
    <div class="search-business" :dir="$direction">
      <el-input
        v-model="searchBusinessText"
        size="small"
        clearable
        :placeholder="$t('businessesPage.table.actions.searchBusiness')"
        @input="onSearch"
      >
        <template #prefix>
          <div class="search-icon">
            <SearchIcon width="16px" height="16px" />
          </div>
        </template>
      </el-input>
    </div>
    <div>
      <DropdownTableFilter
        :filter-name="$t('businessesPage.table.headers.capabilities')"
        :filter-value="
          chosenCapability ? $t(`businessesPage.table.actions.capabilityFilter.${chosenCapability}`) : null
        "
        trigger="click"
        :clearable="true"
        @on-choose-item="onCapabilityChange"
        @on-clear-filter="onCapabilityChange(null)"
      >
        <div slot="filter-value" class="capability-option">
          <component :is="iconNameByCapabilityChosen" v-if="iconNameByCapabilityChosen" :size="20" />
          {{ chosenCapability ? $t(`businessesPage.table.actions.capabilityFilter.${chosenCapability}`) : null }}
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :command="CAPABILITIES_COMMANDS.RESTAURANT">
            <div class="capability-option">
              <StoreIcon :size="20" />
              {{ $t(`businessesPage.table.actions.capabilityFilter.${CAPABILITIES_COMMANDS.RESTAURANT}`) }}
            </div>
          </el-dropdown-item>
          <el-dropdown-item :command="CAPABILITIES_COMMANDS.SUPPLIER">
            <div class="capability-option">
              <SupplierNewIcon :size="20" />
              {{ $t(`businessesPage.table.actions.capabilityFilter.${CAPABILITIES_COMMANDS.SUPPLIER}`) }}
            </div>
          </el-dropdown-item>
          <el-dropdown-item :command="CAPABILITIES_COMMANDS.BOOK_KEEPING">
            <div class="capability-option">
              <BookkeepingIcon :size="20" />
              {{ $t(`businessesPage.table.actions.capabilityFilter.${CAPABILITIES_COMMANDS.BOOK_KEEPING}`) }}
            </div>
          </el-dropdown-item>
          <el-dropdown-item :command="CAPABILITIES_COMMANDS.SERVICE_PROVIDER">
            <div class="capability-option">
              <ClarityIcon :size="20" />
              {{ $t(`businessesPage.table.actions.capabilityFilter.${CAPABILITIES_COMMANDS.SERVICE_PROVIDER}`) }}
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </DropdownTableFilter>
    </div>
    <div class="create-new-business-button-wrapper">
      <Button @click="$emit('on-create-business-button-click')">{{
        $t(`businessesPage.table.actions.createBusiness`)
      }}</Button>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';

import { SearchIcon, BookkeepingIcon, StoreIcon, SupplierNewIcon, ClarityIcon } from '@/assets/icons';
import { DropdownTableFilter, Button } from '@/modules/core';

export const CAPABILITIES_COMMANDS = {
  RESTAURANT: 'restaurant',
  SUPPLIER: 'supplier',
  BOOK_KEEPING: 'bookkeeping',
  SERVICE_PROVIDER: 'serviceProvider',
};

export default {
  components: { SearchIcon, BookkeepingIcon, StoreIcon, SupplierNewIcon, ClarityIcon, DropdownTableFilter, Button },
  props: {
    chosenCapability: { type: String, default: null },
  },
  emits: ['on-search', 'on-capability-change', 'on-create-business-button-click'],
  setup(props, { emit }) {
    const searchBusinessText = ref('');

    const onSearch = (searchText) => {
      emit('on-search', searchText);
    };

    const onCapabilityChange = (newCapabilityChosen) => {
      emit('on-capability-change', newCapabilityChosen);
    };

    const iconNameByCapabilityChosen = computed(() => {
      switch (props.chosenCapability) {
        case CAPABILITIES_COMMANDS.RESTAURANT:
          return 'StoreIcon';
        case CAPABILITIES_COMMANDS.SUPPLIER:
          return 'SupplierNewIcon';
        case CAPABILITIES_COMMANDS.BOOK_KEEPING:
          return 'BookkeepingIcon';
        case CAPABILITIES_COMMANDS.SERVICE_PROVIDER:
          return 'ClarityIcon';
        default:
          return null;
      }
    });

    return {
      CAPABILITIES_COMMANDS,
      searchBusinessText,
      iconNameByCapabilityChosen,
      onSearch,
      onCapabilityChange,
    };
  },
};
</script>
<style scoped lang="scss">
.businesses-table-actions {
  display: flex;
  gap: 2rem;
  justify-content: flex-start;

  .search-business {
    width: 18.75rem;
    .search-icon {
      display: flex;
      align-items: center;
      height: 100%;
      width: 16px;
    }
  }
  .capability-option {
    display: flex;
    gap: 0.25rem;
  }
  .create-new-business-button-wrapper {
    margin-inline-start: auto;
  }
}
</style>
