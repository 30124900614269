<template>
  <div>
    <TableLoadingSkeleton v-if="loading" />
    <Table v-else border rounded :data="businessesData" :columns="columns" :show-index="(page - 1) * limit + 1">
      <template #cell-name="{ rowData: { name, logoUrl } }">
        <div class="business-logo-name">
          <div class="business-logo">
            <img v-if="logoUrl" :src="`${logoUrl}&token=${token}`" width="24" height="24" />
            <ShopIcon v-else class="default-business-logo" />
          </div>
          <TruncatedText class="truncated-text">{{ name }}</TruncatedText>
        </div>
      </template>
      <template #cell-legalName="{ rowData: { legalName } }">
        <TruncatedText v-if="legalName" class="truncated-text">{{ legalName }}</TruncatedText>
        <p v-else>{{ MISSING_DATA_TEXT }}</p>
      </template>
      <template #cell-number="{ rowData: { number } }">
        <TruncatedText v-if="number" class="truncated-text">{{ number }}</TruncatedText>
        <p v-else>{{ MISSING_DATA_TEXT }}</p>
      </template>
      <template #cell-address="{ rowData: { address } }">
        <TruncatedText v-if="address" class="truncated-text">{{ address }}</TruncatedText>
        <p v-else>{{ MISSING_DATA_TEXT }}</p>
      </template>
      <template #cell-capabilities="{ rowData: { capabilities } }">
        <div class="capabilities" :set="(iconNames = getIconNamesByCapabilities(capabilities))">
          <div v-for="(iconName, index) in iconNames" :key="`${iconName}${index}`">
            <p v-if="iconName == null">{{ MISSING_DATA_TEXT }}</p>
            <component :is="iconName" v-else :size="20" />
          </div>
        </div>
      </template>
    </Table>
    <el-pagination
      v-if="showPagination"
      small
      layout="prev, pager, next, jumper"
      background
      :current-page.sync="page"
      :page-size="limit"
      :page-count="Math.ceil(totalCount / limit)"
      class="my-2 float-right pb-4"
      @current-change="onPageChanged"
    />
  </div>
</template>
<script>
import { Table, TableLoadingSkeleton, TruncatedText } from '@/modules/core/components';
import { StoreIcon, SupplierNewIcon, BookkeepingIcon, ShopIcon, ClarityIcon } from '@/assets/icons';

import { CAPABILITIES_COMMANDS } from './BusinessesTableActions.vue';
import { MISSING_DATA_TEXT } from '@/modules/purchase-management/tools/constants';
import { useTenancy } from '@/modules/auth';
import { computed } from 'vue';

export default {
  components: {
    Table,
    TableLoadingSkeleton,
    StoreIcon,
    SupplierNewIcon,
    BookkeepingIcon,
    ShopIcon,
    ClarityIcon,
    TruncatedText,
  },
  props: {
    businessesData: { type: Array, required: true },
    columns: { type: Array, required: true },
    loading: { type: Boolean, required: true },
    totalCount: { type: Number, required: true },
    limit: { type: Number, required: true },
    page: { type: Number, required: true },
  },
  emits: ['on-page-changed'],
  setup(props, { emit }) {
    const { token } = useTenancy();
    const showPagination = computed(() => props.totalCount > props.limit && !props.loading);
    const onPageChanged = (page) => {
      emit('on-page-changed', page);
    };

    const getIconNamesByCapabilities = (capabilities) => {
      if (!capabilities) return MISSING_DATA_TEXT;
      const keys = Object.keys(capabilities);
      const iconNames = [];
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (capabilities[key] === true) {
          switch (key) {
            case CAPABILITIES_COMMANDS.RESTAURANT:
              iconNames.push('StoreIcon');
              break;
            case CAPABILITIES_COMMANDS.SUPPLIER:
              iconNames.push('SupplierNewIcon');
              break;
            case CAPABILITIES_COMMANDS.BOOK_KEEPING:
              iconNames.push('BookkeepingIcon');
              break;
            case CAPABILITIES_COMMANDS.SERVICE_PROVIDER:
              iconNames.push('ClarityIcon');
              break;
            default:
              return null;
          }
        }
      }
      if (iconNames.length == 0) {
        return [null];
      }

      return iconNames;
    };

    return {
      MISSING_DATA_TEXT,
      token,
      showPagination,
      onPageChanged,
      getIconNamesByCapabilities,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

::v-deep {
  tr {
    td {
      cursor: default !important;
    }
  }
}

.business-logo-name {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.business-logo {
  border-radius: 50rem;
  background-color: $secondary;
  margin-right: 0.25rem;
  margin-left: 0.25rem;

  .default-business-logo {
    color: $typography-secondary;
  }
}
.capabilities {
  display: flex;
  gap: 0.25rem;
}

.truncated-text {
  width: 95%;
}
</style>
