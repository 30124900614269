import { gql } from '@apollo/client/core';

export const BUSINESSES = gql`
  query businesses(
    $first: Int
    $after: Int
    $type: String
    $search: String
    $tenant: String
    $capabilities: BusinessCapabilitiesInput
  ) {
    businesses(
      first: $first
      after: $after
      type: $type
      search: $search
      tenant: $tenant
      capabilities: $capabilities
    ) {
      totalCount
      nodes {
        id
        name
        legalName
        tenant
        number
        type
        logoUrl
        countryCode
        contact {
          address
        }
        capabilities {
          restaurant
          bookkeeping
          supplier
          serviceProvider
        }
      }
    }
  }
`;
