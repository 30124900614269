<template>
  <PageLayout>
    <template #header>
      <h1>{{ $t('businesses') }}</h1>
    </template>
    <div class="businesses-page-body">
      <CreateBusinessModal :visible="isBusinessModalVisible" @on-close="isBusinessModalVisible = false" />

      <BusinessesTableActions
        :chosen-capability="chosenCapability"
        @on-search="onSearch"
        @on-capability-change="onChooseCapability"
        @on-create-business-button-click="isBusinessModalVisible = true"
      />
      <BusinessesTable
        :limit="limit"
        :page="page"
        :loading="loading"
        :columns="columns"
        :businesses-data="data"
        :total-count="count"
        @on-page-changed="onPageChanged"
      />
    </div>
  </PageLayout>
</template>

<script>
import { ref, computed } from 'vue';

import { PageLayout } from '@/modules/core/components';

import BusinessesTable from './components/BusinessesTable';
import BusinessesTableActions from './components/BusinessesTableActions';
import { getBusinessesTableColumns } from './components/businessesTableColumns';
import { useBusinesses } from './compositions/businesses';
import CreateBusinessModal from './createBusinessModal/createBusinessModal';

export default {
  components: {
    PageLayout,
    BusinessesTable,
    BusinessesTableActions,
    CreateBusinessModal,
  },
  setup() {
    const limit = ref(25);
    const page = ref(1);
    const searchText = ref('');
    const chosenCapability = ref(null);
    const isBusinessModalVisible = ref(false);

    const { businesses, loading } = useBusinesses(
      computed(() => ({
        first: limit.value,
        after: page.value - 1,
        ...(searchText.value != '' && { search: searchText.value }),
        ...(chosenCapability.value && { capabilities: { [chosenCapability.value]: true } }),
      }))
    );

    const count = computed(() => {
      if (businesses.value.businesses) {
        return businesses.value.businesses.totalCount;
      }
      return 0;
    });
    const data = computed(() => {
      if (businesses.value.businesses) {
        return businesses.value.businesses.nodes.map((value) => {
          return {
            ...value,
            address: value.contact?.address,
          };
        });
      }
      return [];
    });
    const columns = getBusinessesTableColumns();

    const onSearch = (newSearchText) => {
      searchText.value = newSearchText;
      page.value = 1;
    };

    const onPageChanged = (newPage) => {
      page.value = newPage;
    };

    const onChooseCapability = (newCapabilityChosen) => {
      chosenCapability.value = newCapabilityChosen;
      page.value = 1;
    };

    return {
      page,
      data,
      count,
      limit,
      columns,
      loading,
      chosenCapability,
      onSearch,
      onPageChanged,
      onChooseCapability,
      isBusinessModalVisible,
    };
  },
};
</script>

<style scoped lang="scss">
.businesses-page-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>
