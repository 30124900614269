import i18n from '@/imports/startup/client/i18n';

export const BUSINESSES_COLUMNS = {
  NAME: 'name',
  LEGAL_NAME: 'legalName',
  NUMBER: 'number',
  ADDRESS: 'address',
  CAPABILITIES: 'capabilities',
};

export const getBusinessesTableColumns = () => {
  return [
    {
      header: i18n.t('businessesPage.table.headers.name'),
      key: BUSINESSES_COLUMNS.NAME,
      width: '250px',
    },
    {
      header: i18n.t('businessesPage.table.headers.legalName'),
      key: BUSINESSES_COLUMNS.LEGAL_NAME,
      width: '250px',
    },
    {
      header: i18n.t('businessesPage.table.headers.number'),
      key: BUSINESSES_COLUMNS.NUMBER,
      width: '200px',
    },
    {
      header: i18n.t('businessesPage.table.headers.address'),
      key: BUSINESSES_COLUMNS.ADDRESS,
      width: '320px',
    },
    {
      header: i18n.t('businessesPage.table.headers.capabilities'),
      key: BUSINESSES_COLUMNS.CAPABILITIES,
      width: '120px',
    },
  ];
};
