const googlePlacesLanguageCodeByLocaleKey = {
  en: 'en',
  he: 'iw',
};

export const createBusinessFormDefaultValues = {
  name: '',
  legalName: '',
  number: '',
  phone: '',
  tenantEmail: '',
  email: '',
  logoUrl: '',
  countryCode: '',
  websiteUri: '',
  summary: '',
  address: '',
  defaultAddress: '',
  location: '',
  googleMapsUri: '',
  capabilities: {
    restaurant: false,
    supplier: false,
    bookkeeping: false,
  },
};

export const getGooglePlacesLanguageCodeByLocaleKey = (langCode) =>
  googlePlacesLanguageCodeByLocaleKey[langCode] || googlePlacesLanguageCodeByLocaleKey.en;
