import { computed, ref, watch, getCurrentInstance } from 'vue';
import { debounce } from 'lodash';
import config from '@/config';
import { getGooglePlacesLanguageCodeByLocaleKey } from '../constants';
const API_KEY = config.VUE_APP_GOOGLE_PLACES_API_KEY;

const requestFields = [
  'places.displayName',
  'places.formattedAddress',
  'places.location',
  'places.internationalPhoneNumber',
  'places.websiteUri',
  'places.editorialSummary',
  'places.googleMapsUri',
  'places.addressComponents',
];

const optionsBase = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'X-Goog-Api-Key': API_KEY,
    'X-Goog-FieldMask': requestFields.join(','),
  },
};

const googlePlacesApiUrl = 'https://places.googleapis.com/v1/places:searchText';
const DEBOUNCE_TIME = 500;

export function useGooglePlaces(searchQuery) {
  const { $i18n } = getCurrentInstance().proxy;

  const languageCode = getGooglePlacesLanguageCodeByLocaleKey($i18n.locale);

  const respData = ref([]);

  const fetchData = debounce(() => {
    const options = {
      ...optionsBase,
      body: JSON.stringify({ textQuery: searchQuery.value, languageCode }),
    };

    if (!searchQuery.value || !searchQuery.value.length) {
      respData.value = [];
      return;
    }
    fetch(googlePlacesApiUrl, options)
      .then((resp) => resp.json())
      .then((resp) => (respData.value = resp));
  }, DEBOUNCE_TIME);

  watch(searchQuery, fetchData);

  const results = computed(() => respData?.value?.places || []);

  return {
    results,
  };
}

export function mockPlaces(mockData) {
  if (!mockData) {
    return [
      {
        internationalPhoneNumber: '+972 73-382-0039',
        formattedAddress: 'האלון 20, עלי',
        addressComponents: [
          {
            longText: 'עלי',
            shortText: 'עלי',
            types: ['locality', 'political'],
            languageCode: 'iw',
          },
          {
            longText: '4482800',
            shortText: '4482800',
            types: ['postal_code'],
            languageCode: 'iw',
          },
        ],
        location: {
          latitude: 32.0709382,
          longitude: 35.2661401,
        },
        googleMapsUri: 'https://maps.google.com/?cid=849394419179251588',
        websiteUri: 'https://yad-sarah.net/',
        displayName: {
          text: 'יד שרה - עלי',
          languageCode: 'iw',
        },
      },
    ];
  }
}

export function useGoogleMapsUrlCreator(coordinations) {
  const mapZoom = 16;
  const googleMapsStaticUrl = ref(null);

  const generateGoogleMapsUrl = () => {
    if (coordinations.value) {
      googleMapsStaticUrl.value = `https://maps.googleapis.com/maps/api/staticmap?center=${coordinations.value}&zoom=${mapZoom}&markers=${coordinations.value}&size=368x220&key=${API_KEY}`;
    }
  };

  watch(coordinations, generateGoogleMapsUrl);

  return {
    googleMapsStaticUrl,
  };
}
